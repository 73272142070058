import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const TimelineContainer = styled.div`
  padding: 2rem 1rem;
  position: relative;

  .vertical-timeline-element-content {
    position: relative;
    margin-left: 60px;
    background: #fff;
    border-radius: 0.25em;
    padding: 1em;
    box-shadow: none;
  }

  .vertical-timeline::before {
    content: '';
    background: linear-gradient(
      180deg,
      rgba(33, 150, 243, 0.8),
      rgba(255, 87, 34, 0.8)
    );
    width: 4px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    bottom: 0;
  }

  @media (max-width: 768px) {
    padding: 1rem 0;
    .vertical-timeline::before {
      left: 30px;
    }
  }

  @media (max-width: 480px) {
    padding: 0.5rem 0;
    .vertical-timeline::before {
      width: 2px;
      left: 25px;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #fff;
  font-size: 2rem;
  margin-bottom: 3rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
`;

const TimelineIcon = styled(motion.div)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
  }
`;

const TimelineTitle = styled.h3`
  color: ${props => props.color};
  font-size: 1.1rem;
  font-weight: 600;
  text-align: left;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 1rem 1rem 0.5rem 1rem;
  }
`;

const TimelineContent = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(33, 150, 243, 0.2),
    rgba(103, 58, 183, 0.2)
  );
  color: #fff;
  text-align: left;

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

const generateColors = length =>
  Array.from({ length }, (_, i) => {
    const hue = (i * (300 / length) + 60) % 360;
    return `hsl(${hue}, 70%, 60%)`;
  });

const VerticalTimelineComponent = () => {
  const items = [
    {
      label: 'Share your requirements',
      icon: '/augmentation/requirements.png',
    },
    { label: 'Choose developers', icon: '/augmentation/developer.png' },
    { label: 'Onboard your developers', icon: '/augmentation/onboard.png' },
    { label: 'Manage the team', icon: '/augmentation/teams.png' },
    { label: 'Get your project done', icon: '/augmentation/task.png' },
  ];

  const colors = generateColors(items.length);

  return (
    <TimelineContainer>
      <Title>How Our On-Demand IT Staff Augmentations Works?</Title>
      <VerticalTimeline>
        {items.map((item, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentArrowStyle={{ borderRight: 'transparent' }}
            contentStyle={{
              background: 'transparent',
              padding: '0',
            }}
            icon={
              <TimelineIcon>
                <img src={item.icon} alt={item.label} />
              </TimelineIcon>
            }
          >
            <TimelineContent>
              <TimelineTitle color={colors[index]}>{item.label}</TimelineTitle>
            </TimelineContent>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </TimelineContainer>
  );
};

export default VerticalTimelineComponent;
