import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { margin, marginRight, marginTop, padding } from 'styled-system';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import { Typography, Box, Container, Section, Layout, Grid, Figure, Image, Button } from '../components';
import bannerImage from '../images/services/teamAugmentation/teamAugmentationBanner.jpeg';
import contentImgOne from '../images/services/teamAugmentation/teamAug1.jpeg';
import contentImgTwo from '../images/services/teamAugmentation/teamAug2.jpeg';
import ServiceHeroSection from '../components/ServiceHeroSection';
import { stats, techLanguages } from '../config/data';
import Flex from '../components/Flex';
import VerticalTimelineComponent from '../components/VerticalTimeline';

const ServiceContentImage = styled.img`
  box-shadow: 25px 25px 0px 0px var(--secondary-color);
  margin: 25px 0;
  width: 100%;

  @media screen and (max-width: 768px) {
    box-shadow: 10px 10px 0px 0px var(--secondary-color);
  }
`;
const ValueBox = styled(Box)`
  background-color: #061324;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin: 10px;
  height: 150px;

  @media (max-width: 768px) {
    padding: 15px;
    margin: 5px;
  }
`;

const MotionBox = motion(
  styled.div`
    border: 1px solid #152539;
    cursor: pointer;
    background-color: var(--tech-bg);
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  `
);
const techBoxVariants = {
  initial: { opacity: 0, y: 20 },
  hover: { scale: 1.1 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};
const GridContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const teamAugmentation = () => (
  <Layout title="Team augmentaion">
    <Helmet>
      <meta name="description" content="Expand your team with skilled global tech talents, managed by you. Access vetted experts and a dedicated PM for project success and faster time-to-market." />
    </Helmet>
    <Container mt={{ xs: 8, md: 12 }}>
      <ServiceHeroSection title="Team augmentaion" imageSrc={bannerImage} imgAlt="Team augmentaion" />
      <VerticalTimelineComponent />
      <Section>
        <Typography my={5} variant="paragraph1">
          Extend your team with an exciting team of global tech talents. Fill up the skill gaps to achieve both immediate and long-term business goals. Enhance the capacity of your team to scale.
        </Typography>

        <Grid gap="150px" gapSm="15px" alignItems="center" gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }} mb={3}>
          <Box>
            <ServiceContentImage src={contentImgOne} />
          </Box>
          <Box>
            <Typography mt={2} variant="heading5">
              Our Team, You Manage
            </Typography>

            <Typography mt={2} variant="paragraph1">
              Access a large pool of tech talent ready to integrate into your existing teams. Our talents go through pre-employment assessments to ensure they possess the technical aptitude, logical thinking, and communication skills to integrate swiftly into your team.
            </Typography>
          </Box>
        </Grid>

        <Grid gap="150px" gapSm="15px" alignItems="center" gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }} mb={3}>
          <Box flip>
            <Typography mt={2} variant="heading5">
              Your Project, Our Dedicated PM
            </Typography>

            <Typography mt={2} variant="paragraph1">
              A dedicated Project Manager (PM) to oversee the extended team, handle communication, and ensure project success. With our seasoned team and a dedicated PM, your project meets a better time to market.
            </Typography>
          </Box>
          <Box>
            <ServiceContentImage
              // style={{ float: 'right' }}
              src={contentImgTwo}
            />
          </Box>
        </Grid>
      </Section>
      <Section>
        <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr' }}>
          <Box>
            <Typography style={{ textAlign: 'center' }} variant="heading3" mb={3}>
              How Experienced We Are
            </Typography>
          </Box>
        </Grid>
        <Grid
          gridTemplateColumns={{
            _: '1fr',
            sm: '1fr 1fr',
            md: '1fr 1fr 1fr 1fr',
          }}
        >
          {stats.map(stat => (
            <ValueBox key={stat.label}>
              <Typography variant="heading3">{stat.value}</Typography>
              <Typography variant="paragraph1">{stat.label}</Typography>
            </ValueBox>
          ))}
        </Grid>
      </Section>
      <Section>
        <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr' }} justifyItems="center">
          <Box>
            <Typography style={{ textAlign: 'center' }} variant="heading3" mb={3}>
              Technologies We Have Expertise In
            </Typography>
          </Box>
        </Grid>
        <Flex>
          <AnimatePresence>
            {techLanguages.map((tech, index) => (
              <div key={index}>
                <MotionBox initial="initial" animate="enter" exit="exit" whileHover="hover" variants={techBoxVariants} transition={{ duration: 0.5 }}>
                  <Image width="100px" src={tech} alt="Technology tool" />
                </MotionBox>
              </div>
            ))}
          </AnimatePresence>
        </Flex>
      </Section>
      <Section>
        <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr' }}>
          <Box>
            <Typography style={{ textAlign: 'center' }} variant="heading3" mb={3}>
              Ready To Build Your Brand?
            </Typography>
          </Box>
        </Grid>
        <GridContainer>
          <ButtonContainer>
            <Link to="/contact/">
              <Button variant="contained" color="primary" primary>
                Contact Us
              </Button>
            </Link>
            <Link to="/expertise/">
              <Button variant="contained" color="primary">
                View All Services
              </Button>
            </Link>
          </ButtonContainer>
        </GridContainer>
      </Section>
    </Container>
  </Layout>
);
export default teamAugmentation;
